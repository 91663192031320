import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Analytics from '@rushplay/analytics'

import * as Player from './player'

export function TrafficJunky() {
  const MINDGEEK_AFFILIATE_ID = '654570'

  const affiliateId = ReactRedux.useSelector(
    state =>
      Analytics.getAffiliateId(state.analytics) ||
      Player.getAffiliateId(state.player)
  )
  const trafficJunky = ReactRedux.useSelector(state =>
    Player.getTrafficJunky(state.player)
  )

  if (!affiliateId || affiliateId.toString() !== MINDGEEK_AFFILIATE_ID) {
    return null
  }
  if (!trafficJunky) {
    return null
  }

  const buildImgSrc = trafficJunky => {
    switch (trafficJunky.event) {
      case 'REGISTRATION_FUNNEL': {
        return 'https://ctrack.trafficjunky.net/ctrack?action=list&type=add&id=0&context=Playboom&cookiename=Registrationfunnel&age=43200&maxcookiecount=10'
      }
      case 'DEPOSIT_FUNNEL': {
        return 'https://ctrack.trafficjunky.net/ctrack?action=list&type=add&id=0&context=Playboom&cookiename=Despositfunnel&age=43200&maxcookiecount=10'
      }
      case 'REGISTRATION_CONFIRMATION': {
        return `https://ads.trafficjunky.net/tj_ads_pt?a=1000391951&member_id=1004929251&cb=${trafficJunky.cb}`
      }
      case 'DEPOSIT_CONFIRMATION': {
        return `https://ads.trafficjunky.net/tj_ads_pt?a=1000391941&member_id=1004929251&cb=${trafficJunky.cb}`
      }
    }
  }

  const imgSrc = buildImgSrc(trafficJunky)
  if (!imgSrc) {
    return null
  }

  return (
    <React.Fragment>
      <img
        src={imgSrc}
        style={{ display: 'none' }}
        width="1"
        height="1"
        border="0"
      />
    </React.Fragment>
  )
}
